@import '../../styles/colors.module.scss';

.aiport-title {
  font-family: 'Barlow Condensed';
  font-size: 4rem;

  &.icao {
    color: $rally-dark-green-600;
  }

  &.plan-rwy {
    color: $rally-green-600;
    font-weight: bold;
  }
}

.airport-info {
  font-size: 1.5rem;

  &.metar {
    color: $rally-dark-green-600;
  }
}

.route {
  font-size: 1.5rem;
  color: $rally-dark-green-600;
}

.flight-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .airports {
    display: flex;
    position: relative;
    font-size: 4rem;
    font-family: 'Barlow Condensed';

    .flight-number {
      position: absolute;
      top: -16px;
      left: 0;
      font-family: 'Barlow Condensed';
      font-weight: bold;
      font-size: 2rem;
      color: $rally-dark-green-600;
    }
  }

  .aircraft {
    display: flex;
    align-items: center;

    .aircraft-name {
      font-size: 3rem;
    }

    .aircraft-icao {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 2rem;
      font-family: 'Barlow Condensed';
      font-weight: bold;
      color: $rally-dark-green-600;
    }

    .aircraft-reg {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 2rem;
      font-family: 'Barlow Condensed';
      font-weight: bold;
      color: $rally-dark-green-600;
    }
  }
}

.sched-time,
.general-time,
.utc-time,
.airac {
  margin: 0 0.5rem;

  .name {
    margin: 0 0 -1rem 0;
  }

  .time,
  .cycle {
    font-size: 3rem;
    font-weight: bold;
    color: $rally-dark-green-600;
    font-family: 'Barlow Condensed';

    &.not-current {
      color: red;
    }
  }
}

.utc-time {
  width: 12rem;
}

.ofp {
  pre {
    font-family: 'JetBrains Mono';
  }
}

.frequencies > * {
  margin-bottom: 0.5rem;
}

.frequency {
  cursor: pointer;
  display: inline;
  color: $rally-dark-green-600;
  padding: 0.05rem 0.4rem;
  border-radius: 4px;
  font-family: 'JetBrains Mono';
  font-weight: bold;
  border: 1px solid $rally-dark-green-600;
  margin: 1.25rem 0.2rem;

  &.selected {
    background-color: $rally-dark-green-600;
    color: #333;
  }
}
