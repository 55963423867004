@import '../../../styles/colors.module.scss';

.panel-container {
  border: 1px solid $powder-blue;
  border-radius: 4px;
  padding: 16px;
  position: relative;
  height: 100%;
  font-size: 1.5rem;

  .panel-title {
    font-size: 1.5rem;
    position: absolute;
    top: -14px;
    padding: 0 8px;
    background-color: $black-russian;
  }

  .value {
    font-family: 'JetBrains Mono';
    color: $baby-blue;
    text-align: right;
  }
}
