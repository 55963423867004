@import '../../../../styles/colors.module.scss';

.menu-item {
  color: $reply-blue-400;
  text-decoration: none;
  font-size: 2.5rem;
  font-weight: 600;
  font-family: 'Barlow Condensed', sans-serif;

  &.active,
  &:hover {
    color: $reply-blue-200;
    border-bottom: $reply-blue-200 6px solid;
  }

  &.disabled {
    pointer-events: none;
    color: $reply-blue-600;
  }
}
