@import './colors.module.scss';
@import './animations.scss';

html,
body,
#root {
  height: 100%;
}

#map-id {
  height: 100%;
}

.cycle {
  color: $midnight-express;
}

.border-color-port-gore {
  border: 2px solid $port-gore;
}
