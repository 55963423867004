@font-face {
  font-family: 'JetBrains Mono';
  src: url('./jetbrains/JetBrainsMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: url('./jetbrains/JetBrainsMono-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: url('./jetbrains/JetBrainsMono-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
