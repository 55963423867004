@import '../../../styles/colors.module.scss';

$font-size-base: 32px;
$base-font-color: $powder-blue;

h3 {
  &.section {
    font-size: $font-size-base * 1.25 !important;
    font-weight: normal !important;
    text-transform: uppercase;
  }
}

.procedure-container {
  padding: 1rem 1rem;
  color: $base-font-color;

  .procedure-title {
    font-size: $font-size-base * 2;
    font-weight: thin;
    border-bottom: 2px solid $powder-blue;
  }

  .procedure-completed {
    font-size: $font-size-base * 1.25;
    border-bottom: none;
  }

  .section-title-l1 {
    font-size: $font-size-base * 2;
    font-family: 'Barlow Condensed';
    font-weight: bold;
    color: $midnight-express;
    background-color: $baby-blue-25d;
    padding: 0 0 0.5rem 1rem;
    text-transform: uppercase;
  }

  .section-title-l2 {
    font-size: $font-size-base * 1.5;
    font-family: 'Barlow';
    font-weight: bold;
  }

  .task-item {
    font-size: $font-size-base * 1.25;
    font-family: 'JetBrains Mono';
    font-weight: 400;
    text-transform: uppercase;
    color: lighten($base-font-color, 10);

    padding-left: 0;

    span {
      display: flex;
      padding: 2px 8px;
      margin: 4px 0;

      cursor: pointer;

      &.task-done {
        opacity: 0.25;
      }

      &.checklist-done {
        color: grey;
      }
    }

    span .item {
      order: 1;
    }

    span .note {
      color: #666;
      background-color: #333;
      order: 2;
    }

    span .action {
      order: 3;
    }

    span::after {
      background-image: radial-gradient(
        circle,
        currentcolor 1px,
        transparent 1.5px
      );
      background-position: bottom;
      background-size: 1ex 4.5px;
      background-repeat: space no-repeat;
      content: '';
      flex-grow: 1;
      height: 1em;
      order: 2;
    }

    .line-through {
      border-top: 5px solid #333;
      left: 1rem;
      width: calc(100% - 32px);
    }
  }

  .line-through {
    position: absolute;
    left: 0px;
    top: 30px;
    width: 100%;
  }

  .checklist-container {
    border: 5px solid black;
    background-color: white;
    color: black;
    margin: 0 4rem 1rem 4rem;

    .title {
      font-weight: 700;
      padding: 0.5rem 1rem;
    }

    hr.divider {
      border: 2px solid black;
      padding: 0;
      margin: 0;
    }

    .list-container {
      padding: 0 1rem;
    }

    .line-through {
      border-top: 5px solid gray;
    }
  }

  .taskgroup-optional {
    background-color: #aaa;
    color: #333;
    margin: 0 1rem 2rem 1rem;
  }

  .note-component {
    background-color: $port-gore;
    font-family: 'Barlow';
    text-transform: none;
    padding: 1rem;
    border-radius: 0.5rem;
    margin: 0 4rem 2rem 4rem;

    > p {
      margin: 0;
    }
  }
}

.procedure-section-container {
  border-left: 5px solid $rally-blue-500;
  padding: 0.5rem;
  margin: 0.5rem 0 1rem 0;
}

.procedure-item-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  color: $reply-blue-400;
  border-top: solid 1px $reply-blue-800;

  .item {
    display: 'flex';
    flex-direction: 'row';
    width: calc(100% - 125px);
    padding: 1rem;
    font-size: 1.25rem;
    border-radius: 4px;

    &:hover {
      background: $reply-blue-800;
      cursor: pointer;
    }
  }

  .item-type {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-transform: uppercase;
    margin: 0 1rem;
    background-color: rgba($rally-green-600, 0.25);
    width: 10rem;
    height: 2rem;
    color: rgba($rally-green-200, 0.5);
  }

  .operation-button {
    width: 60px;
    display: flex;
    padding: 1rem;
    margin: 0 0.5rem;
    display: flex;
    justify-content: center;
    align-content: center;

    &:hover {
      cursor: pointer;
    }

    .delete {
      color: $rally-orange-600;

      &:hover {
        color: $rally-orange-100;
      }
    }

    .duplicate {
      color: $reply-blue-400;

      &:hover {
        color: $reply-blue-100;
      }
    }

  }
}

.atc-component {
  background-color: #41b23b;
  color: #eee;
  margin: 0 1rem 2rem 1rem;
  font-size: 2rem;
  font-family: 'JetBrains Mono';

  .atc-header {
    display: flex;
  }

  .atc-instruction {
    display: flex;
    justify-content: flex-start;
    color: #233;
    padding: 1rem;
    text-transform: none;

    &.atc {
      background-color: $rally-green-600;
    }

    &.pilot {
      background-color: $rally-green-300;
    }
  }
}

.procedure-edit {
  width: 100%;

  span {
    display: flex;
    padding: 2px 8px;
    cursor: pointer;
    text-transform: uppercase;
  }

  span .note {
    color: #666;
    background-color: #333;
    order: 2;
  }

  span .action {
    order: 3;
  }

  span::after {
    background-image: radial-gradient(
      circle,
      currentcolor 1px,
      transparent 1.5px
    );
    background-position: bottom;
    background-size: 1ex 4.5px;
    background-repeat: space no-repeat;
    content: '';
    flex-grow: 1;
    height: 1.45em;
    order: 2;
  }
}

.dragging-placeholder {
  width: 100%;
  height: 2.43rem;
  border-radius: 0.25rem;
  background-color: $card-background;
}

.task-item {
  .action-button {
    color: $reply-blue-600;
  }
}

.value-error {
  background-color: $rally-orange-600;
  color: $rally-yellow-400;
  border-radius: 0.25rem;
}
