// Blues

$black: #000;
$black-russian: #0a102b;
$midnight-express-darker: #0f1634;
$midnight-express: #182547;
$port-gore: #37466d;
$powder-blue: #748db3;

$baby-blue: #c1e1e5;
$baby-blue-25d: darken($baby-blue, 25%);
$baby-blue-50d: darken($baby-blue, 50%);

// Palette

$rally-green-600: #009d60;
$rally-green-500: #00AC6A;
$rally-green-300: #68c596;
$rally-green-200: #96D5B3;

$rally-dark-green-600: #007c75;
$rally-dark-green-400: #239a95;
$rally-dark-green-200: #7cc3c1;

$reply-orange-800: #f9aa33;
$reply-orange-600: #ffd942;

$rally-orange-600: #f7523e;
$rally-orange-400: #ff6859;
$rally-orange-100: #ffd6d1;

$rally-yellow-400: #ffc40f;

$rally-purple-300: #b15dff;

$rally-blue-500: #00baff;
$rally-blue-200: #72deff;

$reply-blue-900: #1D2A31;
$reply-blue-800: #2e3f48;
$reply-blue-600: #4a6572;
$reply-blue-500: #567383;
$reply-blue-400: #6f8795;
$reply-blue-300: #879ca8;
$reply-blue-200: #a9b8c1;
$reply-blue-100: #cad5da;

$dark-background: $midnight-express-darker;
$default-background: $black-russian;
$card-background: darken($midnight-express, 5);
$card-background-lighter: $port-gore;

// Custom Colors

$mirage: #1a232e;

$affirmative: #48cb66;
$negative: #ea5545;

:export {
  primary_color: $reply-orange-800;
  secondary_color: $reply-blue-200;
  info_color: $rally-blue-500;
  success_color: $rally-green-300;
  error_color: $rally-orange-400;
  warning_color: $rally-yellow-400;

  bodytext_color: $powder-blue;
  bodytextalt_color: $rally-dark-green-200;

  defaultbackground_color: $default-background;
  cardbackground_color: $card-background;
  black_color: $black;

  rallyblue200_color: $rally-blue-200;
}

.color-reply-orange-800 {
  color: $reply-orange-800;
}

.color-powder-blue {
  color: $powder-blue;
}

.color-reply-blue-200 {
  color: $reply-blue-200;
}
