@import './colors.module.scss';

.item-container {
  background-color: rgba(darken($midnight-express, 7%), 1);
  border-radius: 0.25rem;
  padding: 0.5rem 1.5rem;
  font-family: 'Barlow Condensed';
  margin: 0 0 0.5rem 0;

  &:hover {
    background-color: $midnight-express;
    cursor: pointer;
  }
}

.data-display {
  margin-bottom: 1rem;

  div:first-child {
    color: $reply-blue-500;
    text-transform: uppercase;
  }

  div:nth-child(2) {
    font-size: 1.25rem;
    font-weight: bold;

    > span {
      padding-left: 1rem;
      font-weight: normal;
    }
  }
}
