.reactMarkdown {
  font-size: 2rem;

  h1, h2, h3, h4 {
    margin: 0.5rem 0 0.5rem 0;
  }

  h1 {
    font-size: 130%;
  }

  h2 {
    font-size: 110%;
  }


  p {
    font-size: 100%;
  }

  table {
    border-collapse: collapse;
    font-size: 90%;

    th, td {
      border: 1px solid;
      padding: 4px;
    }
  }
}
