@import '../../../styles/colors.module.scss';

.time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Barlow Condensed';
  color: $powder-blue;

  .time {
    font-weight: bold;
    font-size: 1.5rem;
  }

  .name {
    font-weight: 300;
    font-size: 1rem;
    text-transform: uppercase;
  }
}

.timeSep {
  animation: blink 2s cubic-bezier(1, 0, 0, 1) infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
