@font-face {
  font-family: 'Barlow Condensed';
  src: url('./barlow-condensed/BarlowCondensed-ExtraLight.ttf')
    format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('./barlow-condensed/BarlowCondensed-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('./barlow-condensed/BarlowCondensed-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('./barlow-condensed/BarlowCondensed-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Condensed';
  src: url('./barlow-condensed/BarlowCondensed-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
