@font-face {
  font-family: 'Barlow';
  src: url('./barlow/Barlow-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('./barlow/Barlow-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
