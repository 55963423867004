@import './colors.module.scss';

html,
body {
  margin: 0;
  padding: 0;
}

$bpxs: 0px;
$bpsm: 600px;
$bpmd: 960px;
$bplg: 1280px;
$bpxl: 1920px;

$headerFooterHeight: 56px;
$headerFooterZIndex: 2000;
$contentZIndex: 1000;
$leftPanelWidth: 400px;

#flex-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#main::before {
  content: '';
  position: fixed;
  left: 0;
  right: 0;
  z-index: -1;

  display: block;
  background-size: cover;
  width: 100%;
  height: 100%;

  filter: blur(12px);
}

#main {
  flex: 1;
  overflow-y: auto;
  padding: 0.5rem;
}

#header {
  background-color: $card-background;
  flex-basis: $headerFooterHeight;
  z-index: $headerFooterZIndex;

  .first-part {
    width: calc(100% - 56px);
  }
}

#footer {
  flex-basis: $headerFooterHeight;
  margin-top: auto;
  z-index: $headerFooterZIndex;
}

#basic-layout {
  height: calc(100% - 64px);
}

#left-panel {
  position: relative;
  width: $leftPanelWidth;
  height: 100%;
  background: rgba($black-russian, 0.5);
  z-index: $contentZIndex;

  .scrollable {
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
  }
}

#full-page {
  padding-top: 1rem;
  height: 100%;
  width: 100%;
}

#right-panel {
  position: absolute;
  top: 56px;
  left: calc($leftPanelWidth + 0px);
  padding: 1rem 1.5rem;
  width: calc(100% - ($leftPanelWidth + 0px));
  height: calc(100% - 112px);
  background: rgba(#1a232e, 0.5);
}

.flex-center-vert {
  display: flex;
  align-items: center;
  height: 100%;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $reply-blue-200;
}

::-webkit-scrollbar-thumb {
  background-color: $reply-blue-200;
  border-radius: 4px;
}
