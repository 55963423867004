@font-face {
  font-family: 'Brutal';
  src: url('./brutal/BrutalType.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Brutal';
  src: url('./brutal/BrutalType-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
